import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { push } from "connected-react-router";

import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
} from "@ant-design/icons";

import {
  getUsersList,
  removeUser,
  updateRoleResource,
  updateUserAccess,
  resendWelcomeEmail,
} from "./adminSlice";

import CreateUser from "../auth/CreateUser";

import { ROUTER_ADMIN_USERS_NEW } from "../../libs/constants";

const { Option, OptGroup } = Select;

const getRoleValue = (role) => {
  switch (role) {
    // Support Accounts
    case "admin":
      return 1;
    case "support":
      return 2;
    case "demo":
      return 4;
    case "customer":
      return 3;

    // Customer Accounts
    case "analyzer":
      return 5;
    case "supervisor":
      return 6;
    default:
      // error
      return 0;
  }
};

const getRoleColor = (role) => {
  switch (role) {
    // Support Accounts
    case "admin":
      return "#f5222d"; // red
    case "support":
      return "#fa541c"; // volcano
    case "demo":
      return "#fa8c16"; // orange

    // Customer Accounts
    case "analyzer":
      return "#722ed1"; // purple
    case "supervisor":
      return "#2f54eb"; // geekblue
    default:
      return "#1890ff"; // blue
  }
};

function SelectRole(props) {
  const { record } = props;
  return (
    <Select defaultValue={record.role} onChange={props.onChange}>
      <OptGroup label="Novarc/Support">
        {/* <Option value="demo">Demo</Option> */}
        <Option value="support">Support</Option>
        <Option value="admin">Admin</Option>
      </OptGroup>
      <OptGroup label="Customer">
        <Option value="customer">Customer</Option>
        {/* <Option value="analyzer">Analyzer</Option> */}
        {/* <Option value="supervisor">Supervisor</Option> */}
      </OptGroup>
    </Select>
  );
}

function getInputNode(dataIndex, record, options) {
  switch (dataIndex) {
    case "role":
      return <SelectRole record={record} />;

    default:
      return <Input />;
  }
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = getInputNode(dataIndex, record);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [visible]);
};

const ModalForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal
      // title="Invite User to Dashboard"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <CreateUser form={form} layout="vertical" name="userForm" />
    </Modal>
  );
};

// TODO: Recommended to be refactored on v2.0.0
function UserAccessToggle(props) {
  const { record, onConfirm } = props;
  const [checked, setChecked] = useState(record.activated);

  const handleConfirm = (value) => {
    setChecked(!checked);
    onConfirm(record.id);
  };

  return (
    <Popconfirm
      title={checked ? "Sure to deactivate?" : "Sure to activate?"}
      onConfirm={() => handleConfirm(record.activated)}
    >
      <Switch checked={checked} />
    </Popconfirm>
  );
}

function UsersList() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.users);
  const groups = useSelector((state) => state.admin.robots);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  const [form] = Form.useForm();
  // const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [sentId, setSentId] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const usersLists =
    users && users.length > 0
      ? users.map((item, index) => ({ ...item, key: index }))
      : [];
  // eslint-disable-next-line
  const add = (record) => {
    console.log(record);
    // dispatch(createRobotTier(record.id));
  };

  const edit = (record) => {
    console.log("edit", record);
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key, id) => {
    try {
      const row = await form.validateFields();

      const { role } = row;

      const role_id = getRoleValue(role);

      if (role_id) {
        dispatch(
          updateRoleResource({
            id,
            // note there seems to be unnecessary back and forth conversion between role id and value
            requestData: { user_id: id, role_id: getRoleValue(role) },
          }),
        );
      } else {
        throw new Error("Invalid role value");
      }
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleActivation = (id) => {
    dispatch(updateUserAccess({ id }));
  };

  const handleFilter = (key) => {
    return (
      users &&
      users.length > 0 &&
      users
        .map((item) => {
          return item[key];
        })
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((item) => {
          return { text: item, value: item };
        })
    );
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filters: handleFilter("name"),
      filterSearch: true,
      onFilter: (value, record) =>
        record?.name.toLowerCase().indexOf(value.toLowerCase()) === 0,
      // editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: handleFilter("email"),
      filterSearch: true,
      onFilter: (value, record) =>
        record?.email.toLowerCase().indexOf(value.toLowerCase()) === 0,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      filters: handleFilter("company"),
      filterSearch: true,
      onFilter: (value, record) => record?.company.indexOf(value) === 0,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      filters:
        users &&
        users.length > 0 &&
        users
          .map((item) => {
            return item.role;
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
      onFilter: (value, record) =>
        record.role && record.role.indexOf(value) === 0,
      editable: true,
      render: (text, record, index) => {
        return <Tag color={getRoleColor(record.role)}>{record.role}</Tag>;
      },
    },
    {
      title: "Group",
      // dataIndex: "group",
      key: "group",
      // editable: true,
      render: (text, record, index) => {
        return (
          <>
            {record.groups &&
              record.groups.map((item) => <Tag>{item.name}</Tag>)}
          </>
        );
      },
    },

    {
      title: "Activated",
      // dataIndex: "activated",
      key: "activated",
      // editable: true,
      render: (text, record, index) => {
        return (
          <UserAccessToggle
            text={text}
            record={record}
            index={index}
            onConfirm={handleActivation}
          />
        );
      },
      filters: [
        { text: "Activated", value: true },
        { text: "Deactivated", value: false },
      ],
      onFilter: (value, record) => record?.activated === value,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Save?"
              onConfirm={() => save(record.key, record.id)}
            >
              <Button icon={<CheckOutlined />} />
            </Popconfirm>

            <Button icon={<CloseOutlined />} onClick={cancel} />

            <Popconfirm
              title={
                <span>
                  Sure to Send? <br />
                  Once sent, please follow up with customer and avoid spamming
                  with multiple emails.
                </span>
              }
              onConfirm={() => {
                dispatch(resendWelcomeEmail(record.id));
                setSentId(record.id);
              }}
              disabled={sentId === record.id}
            >
              <Tooltip title="Resend Instruction Email only if user never received and not signed in yet">
                <Button
                  type="dashed"
                  icon={<MailOutlined />}
                  disabled={sentId === record.id}
                />
              </Tooltip>
            </Popconfirm>

            <Popconfirm
              title={
                <span>
                  Sure to Delete? <br />
                  Once removed, user will not be able to access NovSync
                  Dashboard
                </span>
              }
              onConfirm={() => {
                dispatch(removeUser(record.id));
                cancel();
              }}
            >
              <Tooltip title="Delete User who are not signed in yet from NovSync Dashboard">
                <Button danger icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </span>
        ) : (
          <Button
            icon={<EditOutlined />}
            disabled={editingKey !== ""}
            // disabled={true}
            onClick={() => edit(record)}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: groups,
      }),
    };
  });
  // eslint-disable-next-line
  const showUserModal = () => {
    setVisible(true);
  };

  const hideUserModal = () => {
    setVisible(false);
  };

  const handleCreateUser = () => {
    dispatch(push(ROUTER_ADMIN_USERS_NEW));
  };

  return (
    <>
      <Button
        // disabled={true}
        // onClick={showUserModal}
        onClick={handleCreateUser}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Invite User
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={usersLists}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
      <ModalForm visible={visible} onCancel={hideUserModal} />
    </>
  );
}

export default UsersList;
